/* App.css */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

* {
  box-sizing: border-box;
}

body {
  background: #f5eff1;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.app-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
  text-align: center;
  background: #f5eff1;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.category-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 15px;
  flex-wrap: wrap;
}

.category-nav a {
  padding: 10px 20px;
  text-decoration: none;
  color: #ff325f;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.category-nav a.active,
.category-nav a:hover {
  background-color: #007BFF;
  color: white;
  transform: translateY(-2px);
}

.title {
  margin-bottom: 20px;
  font-size: 28px;
  color: #333;
  font-family: 'Poppins', sans-serif;
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 15px;
  width: 255px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.product img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.product:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.product:hover img {
  transform: scale(1.05);
}

.ver-button {
  display: block;
  margin-bottom: 10px;
  color: #ff325f;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.ver-button:hover {
  color: #fd5076;
}

.product-info p {
  margin: 5px 0;
}

.product-info .product-title {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.comprar-button {
  background-color: #ff325f;
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 25px;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.comprar-button:hover {
  background-color: #d71d46;
  transform: translateY(-2px);
}

.product-price {
  font-size: 20px;
  color: #333;
  font-weight: bold;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.pagination a {
  margin: 0 5px;
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.pagination a.active,
.pagination a:hover {
  background-color: #ff325f;
  color: white;
  transform: translateY(-2px);
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .product-container {
    display: none;
  }

  .swiper-container {
    padding: 0 20px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  .category-nav {
    display: block;
  }

  .category-nav .swiper-container {
    position: relative;
  }

  .category-nav .swiper-button-prev,
  .category-nav .swiper-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
  }

  .category-nav .swiper-button-prev {
    left: 10px;
  }

  .category-nav .swiper-button-next {
    right: 10px;
  }
}

/* Modal styles */
.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close:hover,
.close:focus {
  color: black;
}

h2 {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
  color: #333;
}

p {
  font-size: 16px;
  color: #555;
  line-height: 1;
}